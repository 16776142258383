<template>
<div id="app">
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <h4>Default Wallet-Card Image</h4>
          </CCardHeader>
          <CCardBody>
              <CForm>

                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol>
                </CRow>
                <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>

                <div class="row">
                  <b-col sm="3">
                    <label for="input-small" >User Tier</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-select v-model="userTier" @change="onchangeTier" :options="optUsrTier"></b-form-select>
                  </b-col>
                </div><br />

                <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Upload Image</label>
                  </b-col>
                  <b-col sm="9">
                      <input type="file" :v-model="srcFile" ref="myFile" @change="onFileChangedImg">
                      <b-button-group>
                      <CButton @click="onUploadImg" :disabled="!isDisabledbtnImg" color="success">Upload</CButton>
                      <CButton  v-on:click="actionShowImg()" color="warning">Show</CButton>
                      <CButton  v-on:click="actionDownloadImg()" color="primary">Download</CButton>
                      </b-button-group>
                  </b-col>
                </b-row><br/>

                <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Preview Image </label>
                     <!-- {{image.width}} * {{image.height}} -->
                  </b-col>
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps" :src="'data:image/png;base64,'+ imgTier " alt=""></b-img>
                  </b-col>
                </b-row><br/>

                <b-row>
                  <b-col sm="3">
                  </b-col>
                  <b-col sm="9">
                      <CButton  v-on:click="actionRefresh()" color="primary">Refresh Preview</CButton>
                  </b-col>
                </b-row><br/>

              </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>
</div>
</template>

<script>
 import AuthService from "@/api/AuthService";
 import AccountService from '@/api/AccountService.js';

 export default {
    name: 'tier_img',
    components: {
    },
    data() {
      return {
          name:'',
          userTier:'',
          description:'',
          isDisabledName:false,
          isDisabledDesc:false,
          image:{
            size:0,
            height:0,
            width:0
          },
          srcFile:'',
          imgType : '',
          imgTier:'',
          defaultImageKey:'',
          optUsrTier : [],
          optImgTier : [],
          selectedFile: null,
          isDisabledbtnImg:false,
          imgProps: {  width: 200, height: 200  },
          spinner:false,
          seen:false,
          msg :'',
          color:'',
          count:1
        };
    },
    created () {
        this.getListImage();
        this.getConfigList();
    },
    methods: {

      actionRefresh(){
          this.getListImage();
          this.getUserTierById(this.userTier);
      },

      onImageLoadFailure (event) {
            event.target.src = 'img/default.png'
      },

      async actionShowImg(){
         var key  = this.defaultImageKey;
         if(key === ""){
             this.msg   = "Tier Image Not Defined !";
             this.color = 'info'; this.seen  = true;
             setTimeout( () =>  this.seen=false , 5000);
         } else {
            var temp = key.split("."); var type  = temp[1];
            this.downloadTierWalletImg(key,type,"true");
         }
      },

      actionDownloadImg(){
        var key  = this.defaultImageKey;
        if(key === ""){
             this.msg   = "Tier Image Not Defined !";
             this.color = 'info'; this.seen  = true;
             setTimeout( () =>  this.seen=false , 5000);
         } else {
            var temp = key.split("."); var type  = temp[1];
            this.downloadTierWalletImg(key,type);
         }
      },

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
         this.isDisabledbtnImg = true;
      },

      onchangeTier() {
          if (this.userTier !== "") {
            this.getUserTierById(this.userTier);
          }
      },

      async onUploadImg() {

          this.spinner = true;
          this.imageLoaded = false;
          let file = this.$refs.myFile.files[0];

          if(!file || file.type.indexOf('image/') !== 0) return;

          this.image.size = file.size;
          let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = evt => {
              let img = new Image();
              img.onload = () => {
                this.image.width  = img.width;
                this.image.height = img.height;
                this.imageLoaded  = true;
              }
              img.src = evt.target.result;
            }
            reader.onerror = evt => { console.error(evt); }
            setTimeout( () =>  this.actionSubmit() , 5000);

      },

      getListImage() {
        AccountService.tierListImg().then(resp => {
          if (resp.statusCode === "S0000") {
            let arrImg = [];
            for(let tier in resp.payload) {
              arrImg[tier] = resp.payload[tier];
            }
            this.optImgTier = arrImg;
          }
        }, error => {
          console.log(error);
        });
      },

      getConfigList: function () {
        AccountService.getUserTierList().then(resp => {  //console.log(resp);
          let usrTLType = resp; let userTLSrc = [];
          userTLSrc.push({ value: '', text: 'Choose User Tier' });
          for (let key in usrTLType) {
            userTLSrc.push({ value: usrTLType[key].code, text: usrTLType[key].code });
          }
          this.optUsrTier = userTLSrc;
        }, error => {
          console.log(error);
        });
      },

      async actionSubmit(){

          //if(this.image.width === 300 && this.image.height === 300) {
          if(this.image.width !== 0 && this.image.height !== 0) {
            const formData = new FormData()
            formData.append('resource', this.selectedFile, this.selectedFile.name);  //console.log(formData);
            formData.append('tier', this.userTier);  //console.log(formData);
            try {
                const resp = await AccountService.uploadWalletTierImg(this.userTier, formData);   //console.log(resp);
                if(resp.statusCode==="S0000"){
                    this.msg   =  !resp.message ? "Upload Wallet Tier Image Success!" : resp.message;
                    this.color = 'success'; this.seen  = true;
                    this.getListImage();
                    this.actionShowImg();
                } else {
                    this.msg   =  !resp.message ? "Upload Wallet Tier Image Failed!" : resp.message;
                    this.color = 'danger'; this.seen  = true;
                }
                this.spinner = false;
                setTimeout( () =>  this.seen=false , 5000);
            } catch (error) { console.log(error); }
          } else {
              this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height;
              this.color = 'warning'; this.seen  = true;  this.spinner = false;
              setTimeout( () =>  this.seen=false , 5000);
          }
      },

      downloadTierImg: function(key,type,show) {
          AccountService.downloadTierImg(key,type).then(resp => {

              if(show==="true"){
                  this.imgTier = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a");
                  a.href = "data:image/png; base64," + ImageBase64;
                  a.download = key; a.click();
              }

          }, error => {
            console.log(error);
          });
      },

      downloadTierWalletImg: function(key,type,show) {
          AccountService.downloadTierWalletImg(key,type).then(resp => {
              if(show==="true"){
                  this.imgTier = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a");
                  a.href = "data:image/png; base64," + ImageBase64;
                  a.download = key; a.click();
              }
          }, error => {
            console.log(error);
          });
      },

      getUserTierById: function(id) {
        AccountService.getUserTierByCode(id).then(resp => {  //console.log(resp);
            this.name = resp.name;
            this.description = resp.description;
            if (id in this.optImgTier) {
              this.defaultImageKey = this.optImgTier[id];
            } else {
              this.defaultImageKey = "";
            }
            this.actionShowImg();
        }, error => {
          this.loading = false;
          console.log(error);
        });
      },

    }
  }
</script>
